@import "@vmf/components/src/assets/style/_index.scss";



























































.x-qr-code {
    > .out-show {
        cursor: pointer;
    }
}
.x-qr-code-img {
    width: 200px;
    height: 200px;
    > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
